import { useState, useRef, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import classnames from 'classnames'
import { clamp, debounce } from '../utility'
import TextInput from './TextInput'
import { useTranslation } from 'react-i18next'
import uiMessageStore from '../stores/uiMessageStore'
import './Autocomplete.scss'

const Autocomplete = ({
  value,
  label,
  hasError,
  required,
  onType,
  onSelect,
  onDismiss = () => {},
  getSuggestions,
  convertSuggestionsToDropdownOptions,
  className,
  style,
}) => {
  const [t] = useTranslation()
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [dropdownCursor, setDropdownCursor] = useState(0)

  const fetchSuggestions = debounce(async (searchString) => {
    try {
      if (!searchString) {
        setIsDropdownOpen(false)
        return
      }

      const response = await getSuggestions(searchString)
      const dropdownOptions = convertSuggestionsToDropdownOptions(response)

      setDropdownOptions(dropdownOptions)
      setIsDropdownOpen(true)
    } catch (e) {
      uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)
    }
  }, 250)

  const debounced = useRef(fetchSuggestions) // persist debounced function reference

  const dropdownRef = useRef()

  const handleClickOutside = useCallback(
    ({ target }) => {
      if (isDropdownOpen && !dropdownRef.current.contains(target)) {
        onDismiss()
        setIsDropdownOpen(false)
      }
    },
    [isDropdownOpen, onDismiss]
  )

  const handleKeyDown = ({ keyCode }) => {
    const minIndex = 0
    const maxIndex = dropdownOptions.length - 1

    if (keyCode === ARROW_KEY_UP) {
      setDropdownCursor(clamp(dropdownCursor - 1, minIndex, maxIndex))
    }

    if (keyCode === ARROW_KEY_DOWN) {
      setDropdownCursor(clamp(dropdownCursor + 1, minIndex, maxIndex))
    }

    if (keyCode === ENTER_KEY) {
      const highlightedOption = dropdownOptions[dropdownCursor]

      if (highlightedOption) {
        onSelect(highlightedOption)
        setIsDropdownOpen(false)
      }
    }

    if (keyCode === ESC_KEY) {
      onDismiss()
      setIsDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [isDropdownOpen, handleClickOutside])

  return (
    <div
      className={classnames('common__autocomplete', className)}
      style={style}
    >
      <TextInput
        label={label}
        value={value}
        hasError={hasError}
        required={required}
        onChange={({ target }) => {
          onType(target.value)
          debounced.current(target.value)
        }}
        onKeyDown={(event) => handleKeyDown(event)}
        style={{ marginBottom: 0 }}
      />
      {isDropdownOpen && (
        <div className="common__autocomplete__options-container">
          <div ref={dropdownRef} className="common__autocomplete__options">
            {dropdownOptions.map((option, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  onSelect(option)
                  setIsDropdownOpen(false)
                }}
                onMouseEnter={() => setDropdownCursor(index)}
                className={classnames('common__autocomplete__option', {
                  'common__autocomplete__option--highlighted':
                    dropdownCursor === index,
                })}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

Autocomplete.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  onType: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  getSuggestions: PropTypes.func.isRequired,
  convertSuggestionsToDropdownOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

const ARROW_KEY_UP = 38
const ARROW_KEY_DOWN = 40
const ENTER_KEY = 13
const ESC_KEY = 27

export default observer(Autocomplete)
