import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Page from '../../../common/components/Page'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, P } from '../../../common/components/Typography'
import TextInput from '../../../common/components/TextInput'
import PinInput from '../../../common/components/PinInput'
import { Button } from '../../../common/components/Button'
import FormErrors from '../../../common/components/FormErrors'
import validateEmailAndToken from '../../../common/validateEmailAndToken'
import uiMessageStore from '../../../common/stores/uiMessageStore'
import apiStore from '../../../common/stores/apiStore'
import { EMAIL_VERIFICATION_ERROR_CODES } from '../../../common/constants'
import { hasUserStronglyIdentified } from '../../../common/helpers'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import { captureException } from '../../../common/errorTracking'

const CompanyAdminVerificationScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const inviteId = searchParams.get('inviteId')

  const initialForm = {
    workEmail: email || '',
    token: '',
  }

  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [requiresStrongIdentification, setRequiresStrongIdentification] =
    useState(false)

  const submit = async () => {
    const errors = validateEmailAndToken(form, t)

    setErrors(errors)

    if (hasErrors(errors)) {
      return
    }

    setIsSubmitting(true)

    try {
      await apiStore.endpoints.user.verifyEmailAddress(
        form.workEmail,
        form.token
      )

      const user = await apiStore.fetchUser()

      if (hasUserStronglyIdentified(user)) {
        setIsSuccess(true)
      } else {
        setRequiresStrongIdentification(true)
      }
    } catch (e) {
      const isRecognizedErrorCode = EMAIL_VERIFICATION_ERROR_CODES.includes(
        e.code
      )

      if (isRecognizedErrorCode) {
        const errorTranslation = isRecognizedErrorCode
          ? `BENEFICIARY:VERIFICATION_SCREEN.ERROR_CODES.${e.code}`
          : 'COMMON:ERRORS.CLIENT_ERROR'

        setErrors([t(errorTranslation)])
      } else {
        uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)

        captureException({
          message: 'Self-service verification code error',
          extra: {
            error: e,
          },
        })
      }
    } finally {
      setIsSubmitting(false)
    }
  }
  if (requiresStrongIdentification) {
    return (
      <Redirect
        route={ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION}
        query={{ inviteId }}
      />
    )
  }

  if (isSuccess) {
    return (
      <Redirect
        route={ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION_SUCCESS}
        query={{ inviteId }}
      />
    )
  }

  const isFormIncomplete = Object.values(form).some((value) => !value)

  return (
    <Page>
      <WhiteBox>
        <H1>{t('BENEFICIARY:VERIFICATION_SCREEN.TITLE')}</H1>
        <P>{t('BENEFICIARY:VERIFICATION_SCREEN.INTRO')}</P>
        <TextInput
          type="email"
          label={t('BENEFICIARY:VERIFICATION_SCREEN.WORK_EMAIL')}
          value={form.workEmail}
          hasError={Boolean(errors.workEmail)}
          onChange={({ target }) =>
            setForm({ ...form, workEmail: target.value })
          }
        />
        <PinInput
          label={t('BENEFICIARY:VERIFICATION_SCREEN.TOKEN')}
          value={form.token}
          digits={4}
          hasError={Boolean(errors.token)}
          onChange={(target) => setForm({ ...form, token: target })}
        />
        {hasErrors(errors) && (
          <FormErrors
            errors={Object.keys(errors).map((key) => errors[key])}
            style={{ marginBottom: '30px' }}
          />
        )}
        <Button
          onClick={submit}
          loading={isSubmitting}
          disabled={isFormIncomplete || isSubmitting}
        >
          {t('COMMON:CONTINUE')}
        </Button>
      </WhiteBox>
    </Page>
  )
}

const hasErrors = (errors) => Boolean(Object.keys(errors).length)

export default observer(CompanyAdminVerificationScreen)
