import * as Sentry from '@sentry/react'
import { makeAutoObservable } from 'mobx'
import { USER_ROLES } from '../constants'
import i18n from '../i18n'
import createEndpoints from './endpoints'
import uiMessageStore from './uiMessageStore'
import userStore from './userStore'

const { REACT_APP_CI_COMMIT_SHA } = process.env

class ApiStore {
  isFetchingUser = false
  csrfToken = undefined
  isFetchingCsrfToken = true
  hasStartedFetchingCsrfToken = false

  constructor() {
    makeAutoObservable(this)

    this.endpoints = createEndpoints(this)
  }

  fetchCsrfToken = async (options) => {
    try {
      if (Boolean(this.csrfToken) || this.hasStartedFetchingCsrfToken) {
        return
      }

      this.hasStartedFetchingCsrfToken = true
      this.isFetchingCsrfToken = true

      const { csrfToken } = await this.getRequest('/api/csrf', options)

      this.csrfToken = csrfToken
    } catch (e) {
      uiMessageStore.addError(i18n.t('COMMON:ERRORS.SERVER_ERROR'), e)
    } finally {
      this.isFetchingCsrfToken = false
      this.hasStartedFetchingCsrfToken = false
    }
  }

  fetchUser = async (options) => {
    try {
      this.isFetchingUser = true

      const user = await this.endpoints.user.getUserinfo(options)

      if (user) {
        Sentry.setUser({
          id: user.sub,
          role: user.role,
        })
      }

      this.isFetchingUser = false

      return user
    } catch (e) {
      uiMessageStore.addError(i18n.t('COMMON:ERRORS.SERVER_ERROR'), e)
    }
  }

  getRequest = async (url, options = {}) => {
    const requestOptions = {
      ...options,
      credentials: options.credentials || 'include',
      headers: {
        ...options.headers,
        'CSRF-Token': this.csrfToken,
      },
    }

    return getRequest(url, requestOptions)
  }

  postRequest = async (url, payload, options = {}) => {
    const requestOptions = {
      ...options,
      credentials: options.credentials || 'include',
      headers: {
        ...options.headers,
        'CSRF-Token': this.csrfToken,
      },
    }

    return postRequest(url, payload, requestOptions)
  }

  putRequest = async (url, payload, options = {}) => {
    const requestOptions = {
      ...options,
      credentials: options.credentials || 'include',
      headers: {
        ...options.headers,
        'CSRF-Token': this.csrfToken,
      },
    }

    return putRequest(url, payload, requestOptions)
  }

  patchRequest = async (url, payload, options = {}) => {
    const requestOptions = {
      ...options,
      credentials: options.credentials || 'include',
      headers: {
        ...options.headers,
        'CSRF-Token': this.csrfToken,
      },
    }

    return patchRequest(url, payload, requestOptions)
  }

  deleteRequest = async (url, payload, options = {}) => {
    const requestOptions = {
      ...options,
      credentials: options.credentials || 'include',
      headers: {
        ...options.headers,
        'CSRF-Token': this.csrfToken,
      },
    }

    return deleteRequest(url, payload, requestOptions)
  }

  uploadRequest = async (url, body, options = {}) => {
    const requestOptions = {
      ...options,
      credentials: options.credentials || 'include',
      headers: {
        ...options.headers,
        'CSRF-Token': this.csrfToken,
      },
    }

    return uploadRequest(url, body, requestOptions)
  }
}

class ApiError extends Error {
  constructor(error) {
    super(error.statusText)

    this.status = error.status // HTTP status (e.g. 400)
    this.statusText = error.statusText // HTTP status as text (e.g. "bad-request")
    this.code = error.code // error code (e.g. "email-token-pair-used")
    this.message = error.message // error message (e.g. "Email token pair has been used already")
    this.json = error.json
  }
}

const getErrorCodeByStatus = (statusCode) => {
  if (statusCode === 400) {
    return 'bad-request'
  }

  if (statusCode === 401) {
    return 'expired' // assumed to be an expired session
  }

  if (statusCode === 401 || statusCode === 403) {
    return 'unauthorized'
  }

  if (statusCode === 408) {
    return 'timeout'
  }

  if (statusCode === 500) {
    return 'internal-error'
  }

  if (statusCode === 503) {
    return 'unavailable'
  }

  if (statusCode >= 501) {
    return 'backend-error'
  }

  return 'COMMON:ERRORS.CLIENT_ERROR'
}

// Checks if a string contains valid JSON
const isJson = (value) => {
  try {
    JSON.parse(value)
  } catch {
    return false
  }

  return true
}

const handleJsonResponse = async (response) => {
  if (response.ok) {
    const text = await response.text()
    const hasJsonBody = isJson(text)

    if (!hasJsonBody) {
      return text
    }

    return JSON.parse(text)
  }

  const error = await response.text()

  const errorAsJson = isJson(error)
    ? JSON.parse(error)
    : { errorCode: undefined, errorMessage: error } // fallback if there's no error object

  if (response.status === 401) {
    // If a user's auth session has expired, ask them to sign in again to continue.
    const currentPage =
      `${window.location.pathname}${window.location.search}`.replace(
        /^\/+/,
        '' // remove leading slash
      )

    const lastRole = userStore.lastUserRole || USER_ROLES.ADMIN

    window.location.href = `/access-denied?from=${currentPage}&role=${lastRole}`

    return
  }

  throw new ApiError({
    status: response.status,
    statusText: getErrorCodeByStatus(response.status),
    code: errorAsJson.errorCode,
    message: errorAsJson.errorMessage,
    json: errorAsJson,
  })
}

const getRequest = async (url, options) => {
  const response = await fetch(url, {
    ...options,
    method: 'GET',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
      'Commit-SHA': REACT_APP_CI_COMMIT_SHA,
    },
  })

  return await handleJsonResponse(response)
}

const uploadRequest = async (url, body, options) => {
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      'Commit-SHA': REACT_APP_CI_COMMIT_SHA,
    },
    body,
  })

  return await handleJsonResponse(response)
}

const postRequest = async (url, payload, options) => {
  const response = await fetch(url, {
    ...options,
    method: 'POST',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
      'Commit-SHA': REACT_APP_CI_COMMIT_SHA,
    },
    body: JSON.stringify(payload),
  })

  return await handleJsonResponse(response)
}

const putRequest = async (url, payload, options) => {
  const response = await fetch(url, {
    ...options,
    method: 'PUT',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
      'Commit-SHA': REACT_APP_CI_COMMIT_SHA,
    },
    body: JSON.stringify(payload),
  })

  return await handleJsonResponse(response)
}

const patchRequest = async (url, payload, options) => {
  const response = await fetch(url, {
    ...options,
    method: 'PATCH',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
      'Commit-SHA': REACT_APP_CI_COMMIT_SHA,
    },
    body: JSON.stringify(payload),
  })

  return await handleJsonResponse(response)
}

const deleteRequest = async (url, payload, options) => {
  const config = {
    ...options,
    method: 'DELETE',
    headers: {
      ...options.headers,
      'Content-Type': 'application/json',
      'Commit-SHA': REACT_APP_CI_COMMIT_SHA,
    },
  }

  if (payload) {
    config.body = JSON.stringify(payload)
  }

  const response = await fetch(url, config)

  return await handleJsonResponse(response)
}

export default new ApiStore()
