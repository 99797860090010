import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import Page from '../../../common/components/Page'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, P } from '../../../common/components/Typography'
import { Button } from '../../../common/components/Button'
import TextInput from '../../../common/components/TextInput'
import PinInput from '../../../common/components/PinInput'
import FormErrors from '../../../common/components/FormErrors'
import validateEmailAndToken from '../../../common/validateEmailAndToken'
import apiStore from '../../../common/stores/apiStore'
import {
  BENEFIT_TYPE_ANNUAL_BALANCE,
  BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
  EMAIL_VERIFICATION_ERROR_CODES,
} from '../../../common/constants'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import '../../../common/styles/form.scss'
import { captureException } from '../../../common/errorTracking'

const VerificationScreen = () => {
  const [t] = useTranslation()
  const [searchParams] = useSearchParams()
  const email = searchParams.get('email')
  const inviteId = searchParams.get('inviteId')
  const benefitType = searchParams.get('benefitType')
  const [hasVerifiedWorkEmailWithToken, setHasVerifiedWorkEmailWithToken] =
    useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [form, setForm] = useState({
    workEmail: email || '',
    token: '',
  })
  const [errors, setErrors] = useState({
    workEmail: false,
    token: false,
  })
  const [errorMessages, setErrorMessages] = useState([])

  const submit = async () => {
    const errors = validateEmailAndToken(form, t)

    setErrors(errors)

    const hasErrors = Boolean(
      Object.keys(errors).filter(($) => errors[$]).length
    )

    if (hasErrors) {
      setErrorMessages(
        Object.keys(errors).map((field) =>
          t('COMMON:INVALID_VALUE_X', {
            field: t(FORM_FIELD_TO_TRANSLATION[field]),
          })
        )
      )

      return
    }

    try {
      setIsSubmitting(true)
      await apiStore.endpoints.user.verifyEmailAddress(
        form.workEmail,
        form.token
      )

      setHasVerifiedWorkEmailWithToken(true)
    } catch (e) {
      const isRecognizedErrorCode = EMAIL_VERIFICATION_ERROR_CODES.includes(
        e.code
      )

      if (!isRecognizedErrorCode) {
        captureException({
          message: 'Beneficiary verification code error',
          extra: {
            error: e,
          },
        })
      }

      const errorTranslation = isRecognizedErrorCode
        ? `BENEFICIARY:VERIFICATION_SCREEN.ERROR_CODES.${e.code}`
        : 'COMMON:ERRORS.CLIENT_ERROR'

      setErrors({
        workEmail: true,
        token: true,
      })

      setErrorMessages([t(errorTranslation)])
    } finally {
      setIsSubmitting(false)
    }
  }

  const inputFormValue = (field, value) =>
    setForm({
      ...form,
      [field]: value,
    })

  if (hasVerifiedWorkEmailWithToken) {
    if (
      benefitType === BENEFIT_TYPE_ANNUAL_BALANCE ||
      benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE
    ) {
      return (
        <Redirect
          route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_SUCCESS}
          query={{ inviteId, benefitType }}
        />
      )
    }

    return (
      <Redirect
        route={ROUTES.BENEFICIARY.VERIFICATION_SUCCESS}
        query={{ inviteId, benefitType }}
      />
    )
  }

  const isFormIncomplete = Object.values(form).some((value) => !value)

  return (
    <Page>
      <WhiteBox>
        <H1>{t('BENEFICIARY:VERIFICATION_SCREEN.TITLE')}</H1>
        <P>{t('BENEFICIARY:VERIFICATION_SCREEN.INTRO')}</P>
        <TextInput
          type="email"
          label={t('BENEFICIARY:VERIFICATION_SCREEN.WORK_EMAIL')}
          value={form.workEmail}
          onChange={({ target }) => inputFormValue('workEmail', target.value)}
          hasError={Boolean(errors.workEmail)}
        />
        <PinInput
          label={t('BENEFICIARY:VERIFICATION_SCREEN.TOKEN')}
          value={form.token}
          digits={4}
          onChange={(value) => inputFormValue('token', value)}
          hasError={Boolean(errors.token)}
        />
        {Boolean(errorMessages.length) && (
          <FormErrors errors={errorMessages} style={{ marginBottom: '30px' }} />
        )}
        <Button
          onClick={submit}
          loading={isSubmitting}
          disabled={isFormIncomplete || isSubmitting}
        >
          {t('COMMON:CONTINUE')}
        </Button>
      </WhiteBox>
    </Page>
  )
}

const FORM_FIELD_TO_TRANSLATION = {
  workEmail: 'BENEFICIARY:VERIFICATION_SCREEN.WORK_EMAIL',
  token: 'BENEFICIARY:VERIFICATION_SCREEN.TOKEN',
}

export default observer(VerificationScreen)
